

nav{
  
    height: 300px;
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    padding-left: 200px;
    color: white;
    box-sizing: border-box;
    
  }
   .head .nav-btn {
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColor);
    visibility: hidden;
    opacity: 0;
    margin-top: -12cm;
   }
   @media only screen and (max-width: 1024px){
    .head .nav-btn{
      visibility: visible;
      opacity: 1;
      
      

    }
    .head nav{
      top : 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: block;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      background-color: var(--mainColor);
      transition: 1s;
      transform: translateY(-100vh);
    }
    .head .responsive_nav{
      transform: none;
    }
    
   }

  
 
  header{
   
    height: 400px;
    
    


  }

  @keyframes example {
    from {background-color:#D2691E	;}
    to {background-color: #0000FF;}
   
  }
   
  
 
  .head{
    display: flex;
    animation-name: example;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    background-color: #D2691E	;
    border-radius: 0 0 300px  300px;
    height: 500px;

  }
  nav a h3:hover{
    color: #FFE95F;
  }
  .menu{
    margin-left: 25px;
    color: white;


  }
  .port{
    width: 40px;
    height: 40px;
    margin: 10px;
    border-radius: 20px;
  }
  .About{
    top: 9cm;
    border: 2px solid #ffe95f;
    width: 70%;
    background-color: white;
    margin-left: 15%;
    height: auto;
    display: flex;
    position: absolute;
    animation:example2 2s linear both;
    z-index: 1;
    flex-wrap: wrap;

}
@keyframes example2 {
  0%   {transform: translateY(20px);}
  100%  {transform: translateY(-20px);}


 
}

@media screen and (max-width: 768px) {
  .About {
    flex-wrap: wrap;
    }
  
  .left-info {
    width: 100%;
    margin-top: 20px;
    flex: 1;
  }

  .info-personel {
    width: 100%;
    margin-bottom: 50px;
    flex: 1;
  }
  .ouadie{
    margin-left: 40px;
    width: auto;
    height:auto;
  }

}
  
  .name{
  }
  .ouadie{
    width: 300px;

  }
.face-icone{
  margin-left: 60px;
  width: 30px;
}

.git-icone{
  margin-left: 20px;

width: 30px;
}
.twitter-icone{
  margin-left: 20px;

  width: 30px;

}
.skype-icone{
  margin-left: 20px;

  width: 30px;
}
.compte{
  margin-top: 20px;
}
.info-personel{
  margin-left: 30px;
}
.spec{
  margin-top: 0px;
}
.descrption{
  max-width: 600px;
 color: #bbbbbb;
  
}
.adresse{
display: flex;
}
.phone{
  background-color: #0000FF;
  width: 70px;
  margin-left: 10px;
  margin-top: 37px;
  color: white;

}
.phone-localisation{
  background-color: #0000FF;
  width: 70px;
  margin-left: 10px;
  margin-top: 17px;
  color: white;

  
}
.phone-numbre{
  margin-left: 10px;

  max-width: 250px;
}
.Email{
  background-color: #0000FF;
  width: 70px;
  margin-left: 10px;
  margin-top: 10px;
  color: white;

}
@media screen and (max-width: 600px) {
.ouadie{
  width: 220px;
}
.title-addresse{
margin-top: 18px;
gap: 30px;
}
}
/* Skills */
.skills{
  padding:  0 0 50px 0 ;
  
   
    top: calc(100% + 20px); /* ajuster la valeur pour l'espacement souhaité */
    margin-top: 520px; 
}
@media screen and (max-width: 1200px) {
  .skills {
    margin-top: 36cm;
    
  }
}
.skill-bx{
  border: 2px solid #ffe95f;
  background: white;
  width: 80%;
  text-align: center;
  justify-items: center;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 60px 50px;
  margin-top: 400px;
  animation:example2 4s linear both;
  clear: both; 
  margin: auto;

  position: relative;
}
.skill-bx h1{
  margin-top: 0px;
  color: #0000FF;
}
 
.skill h5{
  color: black;
  font-size: 20px;
  font-weight: 700;
}
.skill p{
  color: #bbbbbb;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.skill-slider{
  width: 80%;
  margin: 0 auto;
  position: relative;
  display: flex;
}
.skill-slider .item img{
width: 50%;
margin: 0 auto 15px auto;

}
.item{
  animation:example3 2s infinite both;

}
@keyframes example3 {
  0%   {transform: translateY(-10px);}
  50%   {transform: translateY(10px);}
  100%  {transform: translateY(-10px);}
}
  
/* Education*/
.Education{
  text-align: center;
}
.Education p{
  color: #bbbbbb;
}


.vertical-timeline-element-date {
  margin-right: 200px;
  animation:example3 2s infinite both;
  z-index: 1;
  flex-wrap: wrap;
  color:#0000FF ;

}
@keyframes example3 {
0%   {transform: translateX(20px);}
50%  {transform: translateX(-20px);}
100%   {transform: translateX(20px);}
}
.vertical-timeline-element--education{
  animation:example4 5s infinite both;
}
@keyframes example4 {
  0%   {transform: translateX(10px);}
  50%  {transform: translateX(-10px);}
  100%   {transform: translateX(10px);}
  }
.Education-title{
  position: relative;
  color: #0000FF;
  
}
/************ Projects Css ************/
.project-img{
  width: 400px;
  border-radius: 20px;
  margin-top: 20px;
  border-radius: 20px;
}
.projectItem{
  margin-top: 20px;
  width: 450px;
  border: 2px solid #ffe95f;
  background-color:white ;
  border-radius: 30px;
  text-align: center;
}
.projectItem p{
  margin-left: 60px;
  max-width: 400px;
}
.projects{
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
.project-type{
color: #FFAE00;
}
.projectItem span{
  max-width: 300px;

} 

.project-container {
  background-color: white;
  border: 2px solid #FFE95F;
  border-radius: 30px;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

}
.project-container:hover{
  background-color: transparent;
}
.project-container h1{
  text-align: center;
  color: #0000FF;
}
@media screen and (max-width: 1024px) {
  .project-container{
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 90%;
    
  }
  
}
@media screen and (max-width: 600px) {
  .project-container{
    grid-template-columns: 1fr;
    width: var(--container-width-sm);
    
  }
  .projects{
    flex-wrap: wrap;
    grid-template-columns: repeat(1, 1fr);
  }
  .projectItem{
    width: 300px;
    text-align: center;
  }
  .projectItem p{
margin-left: auto;
  }
  .projectItem img{
    width: 280px;
  }
 
 
}
/* CONTACT */
.container.contact__container{
  width:  58%;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns:  30% 58%;
  gap: 12%;

}
.contact__options{ 
  display: flex;
  justify-content: center;
 margin: auto;
  gap: 1.2rem;
  margin-left: -40px;

 


}
.contact__option{
  flex-wrap: wrap;
  width: 250px;
  background-color: #ffe95f;
  border:  2px solid #FFE95F ;
  padding: 1.2rem;
  margin-left: 100px;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.contact__option:hover{
  
  background: transparent ;
  border-color: #FFE95F;
  background-color: #0000FF;
}
.contact{
  border: 2px solid #ffe95f;
  padding-top: 15px;
  width: 80%;
  height: 100%;
  background-color:white ;  
  border-radius: 40px;
  text-align: center;
  margin: auto;
  margin-top: 50px;
}
.contact_title{
  color: #0000FF;
}
.get_in_touch{
  color: #020659;
}
.contact1__option-icon{
 font-size: 1.5rem; 
 margin-bottom: 0.5rem;
}
.contact__option a{
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem ;
}

form{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.2rem;
  width: 600px;
  margin: auto;
  margin-bottom: 15px;
}
.forme{
margin-top: 50px;

}
input, textarea{
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border:  2px solid #040FD9 ;
  resize: none;
}
.btn-primary{
  border:  3px solid #ffe95f;
  width: 200px;
 height: 40px;
 border-radius: 12px;
background-color: #0000FF;
color: white;
font-size: 20px;
}
.btn-primary:hover{
  background-color: #ffe95f;
}
.contact__option-icon{
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 1rem;
}
@media screen and (max-width: 1024px) {
  .container.contact__container{
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  form{
    width: 600px;
  }
}
@media screen and (max-width: 870px) {
  
  form{
    width: 400px;
  }
  .contact__options{
    display: flex;
    flex-wrap: wrap;

  }
}

@media screen and (max-width: 600px) {
  .container.contact__container{
    grid-template-columns: 1fr;
    width: var(--container-width-sm);
  }
  .contact__options{
    flex-wrap: wrap;
    margin-left: -100px;
    
  }
  form{
    width: 250px;
    margin-left: 10px;
  }
 
 
}

/* FOOTER */
footer{
  background-color: #0000FF;
  padding: 3rem 0;
  text-align: center;
  font-size: 0.9rem;
  margin-top: 7rem;
  
}
footer a{
  
  color:#ffe95f ;
}
.footer__logo{
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}
.permalinks{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin: 0 auto 3rem;
  text-decoration: none;
  list-style: none;
}
.permalinks a{
  text-decoration: none;

}
.footer__socials{
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;

}
.footer__socials a{
  background-color: #FFE95F;
  color: var(--color-white);
  padding: 0.8rem;
  border-radius: 0.7rem;
  display: flex;
  border:  1px solid transparent;
}

.footer__socials a:hover{
  background: transparent;
  color: #ffe95f;
  border-color: #FFE95F;

}
.footer__copyright{
  margin-bottom: 10px;
  color: #ffe95f;
}
.footer__logo{
  text-decoration: none;
}